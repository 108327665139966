export default [{
        name: "水岸精品",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s2",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },{
        name: "綠意樂活",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s3",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },{
        name: "豐富機能",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s4",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },{
        name: "精彩直擊",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "s5",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },{
        name: "預約鑑賞",
        //iconSrc:xrequire("@/assets/img/nav/1.png"),
        imgSrc: "",
        subTitle: "",
        section: "order-title",
        svgSrc: "",
        offset: 0,
        mobileOffset: 0
    },
];