import { isMobile } from "../utils/index";
export default {
    address: '新北市五股區成泰路三段356號',
    googleSrc: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1806.5475283303817!2d121.4498633!3d25.0986434!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442af9c08905843%3A0x71c9a16c3c2f019a!2z5b-r5qiC546L5ZyL55CG5Lqr5Z-OIOaOpeW-heS4reW_gw!5e0!3m2!1szh-TW!2stw!4v1724835285527!5m2!1szh-TW!2stw',
    googleLink: 'https://maps.app.goo.gl/EHco4rsJij9ixjiA7',
    phone: "02-8292-3777",
    fbLink: "https://www.facebook.com/103573018971658/",
    fbMessage: "https://m.me/103573018971658/",
    caseName: "伴月灣",
    indigatorLength: 10,
    caseId: 'wv',
    login: 'https://www.h35.tw/admin/test/login',

    houseInfos: [
        ["建案名稱", "伴月灣"],
        ["營造公司", "紹華營造"],
        ["樓層規劃", "地上15層、地下4層"],
        ["企劃銷售", "得邦廣告"],
        ["房型規劃", "2-3房、19~24坪"],
        ["建築執照", "109股建字第0439號 "],
        ["投資興建", "中德建設"]
    ],
    gtmCode: ["5ZQ95PD"], // 可放置多個
    recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
// 主1  6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz
// 主2  6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC
    recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
    recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
    order: {
        title: "立即預約",
        subTitle: "",
        room_type: ['兩房', '三房', '透天'],//房型
    }
};